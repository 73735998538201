export const storeCategories = [
  "Pharmacy & Healthcare",
  "Retail & Fashion",
  "Pet Supplies",
  "Children Apparel & Toys",
  "Cosmetics & Perfumes",
  "Home Interiors",
  "Electronics",
  "Flowers & Plants",
  "Restaurants & Catering",
  "Coffee Shops & Accessories",
  "Bakery & Chocolate",
  "Sports & Recreation",
  "Automotive",
  "Other",
];
