import React, { Component } from "react";
import { Button, Steps } from "antd";
import LoginPage from "./LoginPage";

import { connect } from "react-redux";
import CreationPage from "./CreationPage";

const { Step } = Steps;

class index extends Component {
  renderStepContent = () => {
    if (!this.props.registrationData.authToken) return <LoginPage />;
    return <CreationPage />;
  };
  render() {
    return (
      <div className="text-center mt-3">
        <h1
          style={{
            color: "#0099cc",
            fontWeight: 700,
            marginBottom: 50,
            fontSize: 75,
          }}
        >
          ordable/
        </h1>
        <h4 style={{ position: "relative", top: -45, fontWeight: "bold" }}>
          Ordering, <span style={{ fontWeight: 100 }}>simplified.</span>
        </h4>

        {this.renderStepContent()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentStep: state.registrationData.currentStep,
    registrationData: state.registrationData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(index);
