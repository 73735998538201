import { Input, Button } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { authenticate } from "../../store/actions/registrationData";

class PasswordPage extends Component {
  state = {
    password: "",
    email: "",
    loading: false,
  };
  continueButtonDisabled = () => {
    if (this.state.password?.trim()?.length && this.state.email?.trim()?.length)
      return false;
    return true;
  };
  componentDidMount = () => {};
  render() {
    return (
      <div
        className="mx-auto"
        style={{ maxWidth: 331, width: "calc(100% - 66px)" }}
      >
        <Input
          size="large"
          placeholder="Enter your email"
          style={{ paddingTop: 13, paddingBottom: 13, marginBottom: 15 }}
          onChange={(e) => this.setState({ email: e.target.value })}
        />

        <Input.Password
          size="large"
          placeholder="Enter the password"
          style={{ paddingTop: 13, paddingBottom: 13 }}
          onChange={(e) => this.setState({ password: e.target.value })}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              this.setState({ loading: true });
              this.props.authenticate(this.state.email, this.state.password);
              this.setState({ loading: false });
            }
          }}
        />
        <Button
          className="py-3 mb-4 w-100"
          style={{
            backgroundColor: this.continueButtonDisabled()
              ? "#ebebeb"
              : "#0099cc",
            color: this.continueButtonDisabled() ? "#d6d6d6" : "white",
            height: 60,
            border: "none",
            marginTop: 30,
          }}
          disabled={this.continueButtonDisabled()}
          onClick={() => {
            this.setState({ loading: true });
            this.props.authenticate(this.state.email, this.state.password);
            this.setState({ loading: false });
          }}
          loading={this.state.loading}
        >
          ENTER
        </Button>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    registrationData: state.registrationData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    authenticate: (email, password) => dispatch(authenticate(email, password)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordPage);
