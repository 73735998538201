import "./App.css";
import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import StoreForm from "./components/StoreForm";

class App extends Component {
  render() {
    return (
      <div className="pb-5">
        <Switch>
          <Route path="/" component={StoreForm} />

          {/* <Redirect from="/super-new" to="/super-new/create" /> */}
        </Switch>
      </div>
    );
  }
}

export default App;
