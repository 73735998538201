import {
  SAVE_STEP_INFO,
  GO_BACK,
  TOGGLE_CREATION_LOADING,
} from "../actions/actionTypes";

const initialState = {
  authToken: null,
  reseller: "",
  baseDomain: "",
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "AUTHENTICATE":
      return {
        ...state,
        authToken: payload.token,
        reseller: payload.reseller,
        baseDomain: payload.base_domain,
      };
    default:
      return state;
  }
};
